<script setup lang="ts">
import { Button } from '#components'
import type { IManagerFieldEmits, IManagerFieldProps } from './types'
import {
  useField,
  useFieldCache,
  useFieldCalculation,
  useFieldCast,
} from '@manager'

const props = withDefaults(defineProps<IManagerFieldProps>(), {
  parentValue: () => ({}),
})
const emit = defineEmits<IManagerFieldEmits>()

const {
  modelValue,
  parentValue,
  id,
  type,
  colSize,
  label,
  path,
  validation: { errorMessage },
  help,
  disabled,
  min,
  max,
  handleChange,
} = useField(props, emit)

const { cast } = useFieldCast(props.node)

const { registerCalculated } = useFieldCalculation(props.node, parentValue)
registerCalculated()

// Initialize data field, after setting default value
const { registerData } = useFieldData(props.node, parentValue)
registerData()

const [value, toggle] = useToggle(modelValue.value)

let isUpdating = false
watch(
  () => modelValue.value,
  (v) => {
    if (!isUpdating) {
      isUpdating = true
      value.value = cast(v)
      nextTick(() => (isUpdating = false))
    }
  },
)

watch(value, (v) => {
  if (!isUpdating) {
    modelValue.value = v
    handleChange(v)
  }
})

useFieldCache(props.node, parentValue, props.preserveCache)
</script>

<template>
  <ManagerSharedFieldBase
    :path="path"
    :col-size="colSize"
    :tooltip="node.tooltip"
    :help="help"
    :error-message="errorMessage"
    :usage="node.usage"
  >
    <Button
      :id="id"
      :color="value === true ? 'primary' : 'secondary'"
      size="sm"
      :disabled="disabled"
      @click="toggle()"
    >
      {{ label }}
    </Button>
  </ManagerSharedFieldBase>
</template>
